import React, {Component} from 'react'
import {connect} from 'react-redux'

import Product from './Product'

const PRODUCT_ORDER = {
  bread: 1,
  beer: 2,
  pack: 3,
  other: 4,
}

const sortProduct = (left, right) => (PRODUCT_ORDER[left.type] * 1000 + (left.position || 0)) - (PRODUCT_ORDER[right.type] * 1000 + (right.position || 0))

class Products extends Component {
  render() {
    const {products} = this.props

    return (
      <div>
        <Product product={{}} />
        {
          products
            ? products.sort(sortProduct).map((doc) => <Product key={doc.id} product={doc} />)
            : <div id="loader"><div id="arc" className="data" /></div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  products: state.products,
})

export default connect(mapStateToProps)(Products)
