import React, {Component, Fragment} from 'react'
import CloseIcon from 'react-icons/lib/md/close'
import {DateTime} from 'luxon'

import {db, suffix} from './firebase'
import {Textarea} from './fields'
import {formatAmount, ICONS} from './utils'

const collection = db.collection('orders' + suffix)

const stripeUrl = `https://dashboard.stripe.com${suffix.replace('_', '/')}/payments/`

const formatCharge = (order) => {
  const stripeId = (order.stripe_intent || (order.charge && order.charge.id))
  if (!stripeId) {
    return <em>(paiement en cours)</em>
  }
  return (
    <Fragment>
      <a href={stripeUrl + stripeId}>{formatAmount(order.total || order.charge.amount)}</a> le {DateTime.fromMillis(order.time.toMillis()).toLocaleString(DateTime.DATETIME_SHORT)}
    </Fragment>
  )
}

class StripeOrder extends Component {
  state = {
    edit: false,
    submitting: false,
    error: null,
  }

  handleToggle = () => {
    this.setState({
      edit: !this.state.edit,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const {elements} = event.target

    this.setState({
      submitting: true,
      error: null,
    })

    const {order} = this.props

    collection.doc(order.id).update({
      notes: elements.notes.value.trim(),
    })
    .then(() => {
      this.setState({
        submitting: false,
        edit: false,
      })
    })
    .catch((error) => {
      this.setState({
        submitting: false,
        error,
      })
    })
  }

  render() {
    const {edit, submitting, error} = this.state
    const {order, subscription} = this.props
    const {name, email, phone, products, place, comment, notes} = order
    const intent = subscription ? subscription.stripe_intent : order.stripe_intent

    if (!intent) {
      const time = Date.now() - (subscription || order).time.toMillis()
      if (time > 600000) {
        return null // hide abandonned intents (> 10 minutes)
      }
    }

    if (!edit) {
      const icon = ICONS[place.id === 'home' ? 'home' : 'deposit']
      const extraIcon = subscription ? ICONS.subscription : null
      return <div className={'item' + (intent ? '' : ' pending')}><a onClick={this.handleToggle}>{ICONS.card} {icon} {name} {extraIcon}</a></div>
    }

    return (
      <form className="inline" onSubmit={this.handleSubmit}>
        <a className="close" onClick={this.handleToggle}><CloseIcon /></a>
        <h3>{name}</h3>
        <p><a href={'mailto:' + email}>{email}</a></p>
        <p><a href={'tel:' + phone}>{phone}</a></p>
        <ul>
          {products.map((product) => (
            <li key={product.id}>{ICONS[product.type]} {product.amount} × {product.name}</li>
          ))}
        </ul>
        <p>{place.id === 'home' ? ('À domicile : ' + place.address) : ('Point de retrait : ' + place.name)}</p>
        {subscription ? <p>Abonnement: {subscription.duration} semaines (payé {formatCharge(subscription)})</p> : <p>Payé : {formatCharge(order)}</p>}
        {comment && <p>Commentaire client :<br /><span className="comment">{comment}</span></p>}
        <Textarea name="notes" label="Notes internes" defaultValue={notes} />
        <button type="submit" disabled={submitting}>Enregistrer</button>
        {/*charge && <a onClick={this.handleRefund} className="delete">Rembourser</a>*/}
        {error && <p className="error">{error.message}</p>}
      </form>
    )
  }
}

export default StripeOrder
