import React, {Component} from 'react'
import {connect} from 'react-redux'

import Event from './Event'

class Events extends Component {
  render() {
    const {events} = this.props

    return (
      <div>
        <Event event={{}} />
        {
          events
            ? events.map((doc) => <Event key={doc.id} event={doc} />)
            : <div id="loader"><div id="arc" className="data" /></div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  events: state.events,
})

export default connect(mapStateToProps)(Events)
