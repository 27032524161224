import React, {Component} from 'react'
import {connect} from 'react-redux'
import {DateTime} from 'luxon'

import StripeOrder from './StripeOrder'
import PersonalOrder from './PersonalOrder'

const totals = (event, orders) => {
  if (!event) {
    return ' / fournée non créée'
  }
  const sums = {
    beer: 0,
    bread: 0,
    pack: 0,
    other: 0,
  }
  orders.forEach((order) => {
    if (order.total && !order.stripe_intent && !order.subscription) {
      // only count confirmed orders
      return
    }
    order.products.forEach(({type, amount}) => {
      sums[type] += amount
    })
  })
  const texts = []
  if (sums.bread) {
    texts.push(`${sums.bread} pain${sums.bread > 1 ? 's' : ''}`)
  }
  if (sums.beer) {
    texts.push(`${sums.beer} bière${sums.beer > 1 ? 's' : ''}`)
  }
  if (sums.pack) {
    texts.push(`${sums.pack} pack${sums.pack > 1 ? 's' : ''}`)
  }
  if (sums.other) {
    texts.push(`${sums.other} accessoire${sums.other > 1 ? 's' : ''}`)
  }
  return texts.length ? ' / ' + texts.join(' + ') : ''
}

class Orders extends Component {
  renderList = () => {
    const {orders, events, subscriptions} = this.props

    if (!orders || !events || !subscriptions) {
      return <div id="loader"><div id="arc" className="data" /></div>
    }

    return orders.map(({event, items}) => (
      <div key={event}>
        <h2>{DateTime.fromISO(event).setLocale('fr').toFormat('EEEE d MMMM')}<span>{totals(events[event], items)}</span></h2>
        {items.map((order) => {
          if (order.charge || order.subscription || order.total) {
            return <StripeOrder key={order.id} order={order} subscription={subscriptions[order.subscription]} />
          }
          return <PersonalOrder key={order.id} order={order} />
        })}
      </div>
    ))
  }

  render() {
    return (
      <div>
        <PersonalOrder order={{}} />
        {this.renderList()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  orders: state.orders,
  events: state.eventsMap,
  subscriptions: state.subscriptionsMap,
})

export default connect(mapStateToProps)(Orders)
