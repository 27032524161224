const initialState = {
  loading: true,
  user: null,
  error: null,
  events: null,
  products: null,
  places: null,
  orders: null,
  subscriptions: null,
  settings: null,
}

const byId = (data) => {
  const map = {}
  data.forEach((item) => {
    map[item.id] = item
  })
  return map
}

export default (state = initialState, action = null) => {
  switch (action.type) {
    case 'LOGGED_IN':
      return {
        ...state,
        user: action.user,
        loading: false,
      }
    case 'LOGGED_OUT':
      return {
        ...initialState,
        loading: false,
      }
    case 'DATA':
      const newState = {
        ...state,
        [action.name]: action.data,
      }
      if (action.map) {
        newState[action.name + 'Map'] = byId(action.data)
      }
      return newState
    case 'SETTINGS':
      return {
        ...state,
        settings: action.data,
      }
    case 'ERROR':
      return {
        ...state,
        error: action.error,
      }
    default:
      return state
  }
}
