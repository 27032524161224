import React, {Component} from 'react'

import {getFile, formatAmount, ICONS} from './utils'

export const Select = ({label, children, ...props}) => (
  <label>
    {label} : <br />
    <select {...props}>
      {children}
    </select>
  </label>
)

const AUTOCOMPLETE_ATTRIBUTES = {
  phone: 'tel-national',
}

export const Input = ({label, ...props}) => (
  <label>
    {label} : <br />
    <input type="text" autoComplete={AUTOCOMPLETE_ATTRIBUTES[props.name] || props.name} {...props} />
  </label>
)

export const Textarea = ({label, rows, className, ...props}) => (
  <label className={className}>
    {label} : <br />
    <textarea rows={rows || 3} {...props} />
  </label>
)

export const Choices = ({name, label, choices, defaultValue}) => (
  <div className="group">
    <label>{label} :</label>
    {choices.map(({id, title}) => {
      let checked = false
      if (typeof defaultValue === 'boolean') {
        checked = defaultValue
      } else if (defaultValue) {
        checked = defaultValue[id]
      }
      return (
        <label key={id}>
          <input type="checkbox" name={name} value={id} defaultChecked={checked} /> {title}
        </label>
      )
    })}
  </div>
)

export class Products extends Component {
  render() {
    const {name, label, choices, defaultValue} = this.props //TODO: handle products in defaultValue that are not anymore in choices (deleted)

    const defaultAmounts = {}
    if (defaultValue) {
      defaultValue.forEach(({id, amount}) => {
        defaultAmounts[id] = amount
      })
    }

    return (
      <div className="group">
        <label>{label} :</label>
        {choices.map((choice) => {
          return (
            <label key={choice.id}>
              <input type="number" min="0" step="1" name={`${name}.${choice.id}`} defaultValue={defaultAmounts[choice.id] || 0} />
              {ICONS[choice.type]}
              {choice.title} ({formatAmount(choice.price)})
            </label>
          )
        })}
      </div>
    )
  }
}

export class File extends Component {
  state = {}

  handleChange = (event) => {
    const file = getFile(event, this.props.maxSize, this.handlePreview)
    if (!file) {
      this.setState({
        preview: null,
      })
    }
  }

  handlePreview = (event) => {
    this.setState({
      preview: event.target.result,
    })
  }

  render() {
    const {label, defaultValue, ...props} = this.props
    const {preview} = this.state

    let image
    if (preview) {
      image = <img src={preview} alt={label} />
    } else if (defaultValue) {
      image = <img src={defaultValue} alt={label} />
    }

    return (
      <label>
        {label} : <br />
        {image}
        <input type="file" accept="image/*" multiple={false} onChange={this.handleChange} className={image && 'hidden'} {...props} />
      </label>
    )
  }
}
