import React, {Component} from 'react'
import {connect} from 'react-redux'
import {BrowserRouter as Router, Switch, Route, Redirect, NavLink} from 'react-router-dom'
import CartIcon from 'react-icons/lib/md/shopping-cart'
import EventIcon from 'react-icons/lib/md/event'
import EuroIcon from 'react-icons/lib/md/euro-symbol'
import StoreIcon from 'react-icons/lib/md/store'
import PlaceIcon from 'react-icons/lib/md/place'
import SettingsIcon from 'react-icons/lib/md/settings'

import './App.css'

import Login from './Login'
import Events from './Events'
import Accounting from './Accounting'
import Products from './Products'
import Places from './Places'
import Orders from './Orders'
import Settings from './Settings'

class App extends Component {
  render() {
    const {loading, user} = this.props

    if (loading) {
      return <div id="loader"><div id="arc" className="js" /></div>
    }

    if (!user) {
      return <Login />
    }

    return (
      <Router>
        <div>
          <nav>
            <NavLink to="/orders"><CartIcon /></NavLink>
            <NavLink to="/events"><EventIcon /></NavLink>
            <NavLink to="/accounting"><EuroIcon /></NavLink>
            <NavLink to="/products"><StoreIcon /></NavLink>
            <NavLink to="/places"><PlaceIcon /></NavLink>
            <NavLink to="/settings"><SettingsIcon /></NavLink>
          </nav>
          <Switch>
            <Route path="/orders" component={Orders} />
            <Route path="/events" component={Events} />
            <Route path="/accounting" component={Accounting} />
            <Route path="/products" component={Products} />
            <Route path="/places" component={Places} />
            <Route path="/settings" component={Settings} />
            <Redirect to="/orders" />
          </Switch>
        </div>
      </Router>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.loading,
  user: state.user,
})

export default connect(mapStateToProps)(App)
