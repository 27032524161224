import React, {Component} from 'react'

import {auth} from './firebase'

import './Login.css'

class Login extends Component {
  state = {
    loading: false,
    error: null,
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.setState({
      loading: true,
      error: null,
    })

    const {email, password} = document.forms.login.elements

    auth.signInWithEmailAndPassword(email.value, password.value)
    .then(() => {
      this.setState({
        loading: false,
      })
    })
    .catch((error) => {
      this.setState({
        loading: false,
        error: error.message,
      })
    })
  }

  render() {
    const {loading, error} = this.state

    return (
      <form name="login" onSubmit={this.handleSubmit}>
        <input type="email" name="email" placeholder="e-mail" />
        <input type="password" name="password" placeholder="password" />
        <p className="error">{error}</p>
        <button type="submit" disabled={loading}>Login</button>
      </form>
    )
  }
}

export default Login
