import {DateTime} from 'luxon'
import {db, auth, toArray, toGroups, documentId, suffix} from './firebase'

const events = db.collection('events')
const products = db.collection('products')
const places = db.collection('places')
const settings = db.collection('meta').doc('settings')
const orders = db.collection('orders' + suffix)
const subscriptions = db.collection('subscriptions' + suffix)

let listeners = []

const listen = () => (dispatch) => {
  const onError = (error) => {
    dispatch({
      type: 'ERROR',
      error,
    })
    if (window.Rollbar) {
      Rollbar.error(error) /*global Rollbar:false*/
    } else {
      console.error(error) // eslint-disable-line no-console
    }
  }

  const fromDate = DateTime.local().toISODate()

  listeners.push(events.where(documentId(), '>=', fromDate).onSnapshot((snapshot) => {
    dispatch({
      type: 'DATA',
      name: 'events',
      data: toArray(snapshot, 'id'),
      map: true,
    })
  }, onError))
  listeners.push(products.onSnapshot((snapshot) => {
    dispatch({
      type: 'DATA',
      name: 'products',
      data: toArray(snapshot),
    })
  }, onError))
  listeners.push(places.onSnapshot((snapshot) => {
    dispatch({
      type: 'DATA',
      name: 'places',
      data: toArray(snapshot),
    })
  }, onError))
  listeners.push(orders.where('event', '>=', fromDate).onSnapshot((snapshot) => {
    dispatch({
      type: 'DATA',
      name: 'orders',
      data: toGroups(snapshot, 'event'),
    })
  }, onError))
  listeners.push(subscriptions.where('end', '>=', fromDate).onSnapshot((snapshot) => {
    dispatch({
      type: 'DATA',
      name: 'subscriptions',
      data: toArray(snapshot),
      map: true,
    })
  }, onError))
  listeners.push(settings.onSnapshot((snapshot) => {
    dispatch({
      type: 'SETTINGS',
      data: snapshot.data(),
    })
  }, onError))
}

export const authState = (user) => (dispatch) => {
  if (user) {
    dispatch({
      type: 'LOGGED_IN',
      user,
    })
    dispatch(listen())
  } else {
    dispatch({
      type: 'LOGGED_OUT',
    })
  }
}

export const logout = () => () => {
  listeners.forEach((unsubscribe) => unsubscribe())
  listeners = []
  auth.signOut()
}
