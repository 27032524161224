import React, {Component} from 'react'
import CloseIcon from 'react-icons/lib/md/close'
import slug from 'slug'

import {db, storage} from './firebase'

import {Select, Input, Textarea, File} from './fields'
import {ICONS} from './utils'

const collection = db.collection('products')
const storageRef = storage.ref('products')

class Product extends Component {
  state = {
    edit: false,
    submitting: false,
    error: null,
  }

  handleToggle = () => {
    this.setState({
      edit: !this.state.edit,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const {elements} = event.target

    const type = elements.type.value
    const availability = elements.availability.value
    const title = elements.title.value.trim()

    const price = Math.round(elements.price.value.trim() * 100)
    const weight = elements.weight.value.trim()

    if (type !== 'other' && !weight) {
      elements.weight.focus()
      return
    }

    const {product} = this.props

    const doc = product.id ? collection.doc(product.id) : collection.doc()

    const file = elements.photo.files[0]
    let upload
    if (file) {
      if (file.type !== 'image/jpeg') {
        alert('Seuls les fichiers JPG sont autorisés')
        return
      }
      if (file.size > 150000) {
        alert(`Le fichier est trop gros (max 150 ko, il fait ${Math.round(file.size / 1000)} ko)`)
        return
      }
      const filename = slug(title) + '.jpg'
      upload = storageRef.child(doc.id).child(filename).put(file).then((snapshot) => snapshot.ref.getDownloadURL())
    } else {
      upload = Promise.resolve()
    }

    this.setState({
      submitting: true,
      error: null,
    })

    upload
    .then((url) => doc.set({
      type,
      availability,
      position: Number(elements.position.value) || null,
      title,
      description: elements.description.value.trim(),
      price,
      weight: type !== 'pack' ? Number(weight) : weight,
      photo: url || product.photo || null,
    }))
    .then(() => {
      this.setState({
        submitting: false,
        edit: false,
      })
    })
    .catch((error) => {
      this.setState({
        submitting: false,
        error,
      })
    })
  }

  handleDelete = () => {
    if (confirm('Supprimer ce produit ?!')) { // eslint-disable-line no-restricted-globals
      collection.doc(this.props.product.id).delete()
    }
  }

  render() {
    const {edit, submitting, error} = this.state
    const {product} = this.props

    if (!edit) {
      return <div className="item"><a onClick={this.handleToggle}>{ICONS[product.type] || ICONS.new} {ICONS[product.availability] || ''} {product.title || 'Nouveau produit'}</a></div>
    }

    return (
      <form className="inline" onSubmit={this.handleSubmit}>
        <a className="close" onClick={this.handleToggle}><CloseIcon /></a>
        <Select name="type" label="Type" defaultValue={product.type}>
          <option value="bread">{ICONS.bread} Pain</option>
          <option value="beer">{ICONS.beer} Bière</option>
          <option value="pack">{ICONS.pack} Pack</option>
          <option value="other">{ICONS.other} Autre</option>
        </Select>
        <Select name="availability" label="Disponibilité" defaultValue={product.availability}>
          <option value="temporary">{ICONS.temporary} Éphémère</option>
          <option value="permanent">{ICONS.permanent} Permanent</option>
          <option value="disabled">{ICONS.disabled} Non disponible</option>
        </Select>
        <Input name="position" label="Position" defaultValue={product.position} placeholder="Plus grande = plus bas" type="number" step="1" />
        <Input name="title" label="Nom" defaultValue={product.title} required={true} />
        <Textarea name="description" label="Description" defaultValue={product.description} />
        <Input name="price" label="Prix unitaire" defaultValue={product.price ? (product.price / 100) : null} type="number" placeholder="€" step="0.01" min="0" required={true} />
        <Input name="weight" label="Poids en grammes (ou centilitres pour la bière)" defaultValue={product.weight} placeholder="g / cl / texte libre pour les packs" />
        <File name="photo" label="Photo" defaultValue={product.photo} />
        <button type="submit" disabled={submitting}>Enregistrer</button>
        {product.id && <a onClick={this.handleDelete} className="delete">Supprimer</a>}
        {error && <p className="error">{error.message}</p>}
      </form>
    )
  }
}

export default Product
