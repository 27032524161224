import React, {Component} from 'react'
import {connect} from 'react-redux'

import Place from './Place'

const getWeight = (types) => {
  if (!types) {
    return 0
  }
  if (types.deposit) {
    return types.sale ? 2 : 1
  }
  if (types.sale) {
    return 3
  }
  if (types.restaurant) {
    return 4
  }
  return 5
}

const getPosition = (place) => (place.position || 0) + 100000 * getWeight(place.types)

const sortByPosition = (left, right) => getPosition(left) - getPosition(right)

class Places extends Component {
  render() {
    const {places} = this.props

    return (
      <div>
        <Place place={{}} />
        {
          places
            ? places.sort(sortByPosition).map((doc) => <Place key={doc.id} place={doc} />)
            : <div id="loader"><div id="arc" className="data" /></div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  places: state.places,
})

export default connect(mapStateToProps)(Places)
