import React, {Component} from 'react'
import CloseIcon from 'react-icons/lib/md/close'

import {db} from './firebase'

import {Input, Choices, Textarea} from './fields'
import {extractFromCheckboxes} from './utils'
import {formatAmount} from './utils'

const doc = db.collection('meta').doc('settings')

const formatChoices = (data, choices) => {
  const list = []
  for (const key in data) {
    if (data[key]) {
      list.push(choices[key])
    }
  }
  return list.join(', ')
}

class Setting extends Component {
  state = {
    edit: false,
    submitting: false,
    error: null,
  }

  handleToggle = () => {
    this.setState({
      edit: !this.state.edit,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const {elements} = event.target

    this.setState({
      submitting: true,
      error: null,
    })

    const {id, name, type} = this.props
    const key = `${id}.${name}`

    let value = elements[key].value

    if (type === 'number' || type === 'price') {
      value = Number(type === 'price' ? Math.round(value * 100) : value)
    } else if (type === 'choices') {
      value = extractFromCheckboxes(elements[key])
    }

    doc.update({
      [key]: value,
    })
    .then(() => {
      this.setState({
        submitting: false,
        edit: false,
      })
    })
    .catch((error) => {
      this.setState({
        submitting: false,
        error,
      })
    })
  }

  render() {
    const {edit, submitting, error} = this.state
    const {type, id, name, label, value, choices, rows, caption} = this.props
    const key = `${id}.${name}`

    if (!edit) {
      let formattedValue = value
      if (type === 'price') {
        formattedValue = formatAmount(value)
      } else if (type === 'choices') {
        formattedValue = formatChoices(value, choices)
      } else if (type === 'text') {
        formattedValue = value && value.substr(0, 60) + '…'
      }
      return <div className="item"><a onClick={this.handleToggle}>{label} :<br /><span>{formattedValue}</span></a></div>
    }

    let content

    if (type === 'number') {
      content = <Input name={key} label={label} defaultValue={value} type="number" min="0" step="1" />
    } else if (type === 'price') {
      content = <Input name={key} label={label} defaultValue={value && (value / 100)} type="number" min="0" step="0.01" placeholder="€" />
    } else if (type === 'choices') {
      const choicesArray = []
      for (const choiceId in choices) {
        choicesArray.push({id: choiceId, title: choices[choiceId]})
      }
      content = <Choices name={key} label={label} choices={choicesArray} defaultValue={value || {}} />
    } else if (type === 'text') {
      content = <Textarea className="fullwidth" name={key} label={label} defaultValue={value} rows={rows} />
    }

    return (
      <form className="inline" onSubmit={this.handleSubmit}>
        <a className="close" onClick={this.handleToggle}><CloseIcon /></a>
        {content}
        {caption && <p>{caption}</p>}
        <button type="submit" disabled={submitting}>Enregistrer</button>
        {error && <p className="error">{error.message}</p>}
      </form>
    )
  }
}

export default Setting
