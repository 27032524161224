import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {createLogger} from 'redux-logger'

import {auth} from './firebase'
import reducer from './reducer'
import {authState} from './actions'
import App from './App'

import './index.css'

let store
if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({
    errorTransformer: (error) => {
      console.error(error) // eslint-disable-line no-console
      return error
    },
    collapsed: true,
  })
  store = createStore(reducer, applyMiddleware(thunk, logger))
} else {
  store = createStore(reducer, applyMiddleware(thunk))
}

auth.onAuthStateChanged((user) => {
  store.dispatch(authState(user))
})

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'))
