import React, {Component} from 'react'
import {connect} from 'react-redux'

import {logout} from './actions'
import Setting from './Setting'

import {DAYS, PARTNER_CHOICES} from './utils'

const SETTINGS = [
  {id: 'order', name: 'max_bread_units_per_event', label: 'Nombre de pains maximum par journée', type: 'number'},
  {id: 'subscribe', name: 'delivery_days', label: 'Abonnements: Jours de livraison possibles', type: 'choices', choices: DAYS},
  {id: 'subscribe', name: 'min_duration_weeks', label: 'Abonnements: Nombre de semaines minimum', type: 'number'},
  {id: 'subscribe', name: 'max_duration_weeks', label: 'Abonnements: Nombre de semaines maximum', type: 'number'},
  {id: 'subscribe', name: 'random_bread_price', label: 'Abonnements: Prix du pain de saison', type: 'price'},
  {id: 'subscribe', name: 'random_bread_weight', label: 'Abonnements: Poids en grammes du pain de saison', type: 'number'},
]

PARTNER_CHOICES.forEach(({id, title}) => {
  SETTINGS.push({
    id,
    name: 'title',
    label: `${title}: Titre`,
    type: 'text',
    rows: 3,
  })
  SETTINGS.push({
    id,
    name: 'subtitle',
    label: `${title}: Sous-titre`,
    type: 'text',
    rows: 4,
  })
  SETTINGS.push({
    id,
    name: 'text',
    label: `${title}: Texte`,
    type: 'text',
    rows: 8,
    caption: 'Markdown: *italique*, **gras**',
  })
})

class Settings extends Component {
  handleLogout = () => {
    this.props.logout()
  }

  render() {
    const {settings} = this.props

    return (
      <div className="settings">
        {
          settings
            ? SETTINGS.map((props) => (
              <Setting
                key={`${props.id}.${props.name}`}
                value={settings[props.id] && settings[props.id][props.name]}
                {...props}
              />
            ))
            : <div id="loader"><div id="arc" className="data" /></div>
        }
        <p className="logout"><a onClick={this.handleLogout}>Se déconnecter</a></p>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings,
})

export default connect(mapStateToProps, {logout})(Settings)
