export const getFile = (event, maxSize, previewCallback) => {
  const file = event.target.files[0]
  if (!file) {
    return null
  }
  if (maxSize && file.type.indexOf('image') !== 0) {
    alert('Type de fichier non supporté')
    return null
  }
  if (maxSize && file.size > (maxSize * 1024)) {
    alert(`Fichier trop gros : ${Math.round(file.size / 1024)} ko (max ${maxSize} ko)`)
    return null
  }

  if (!previewCallback || !window.FileReader) {
    return file
  }

  const dataReader = new FileReader()
  dataReader.onload = previewCallback
  dataReader.readAsDataURL(file)

  //event.target.value = null // https://stackoverflow.com/a/12102992/1052033

  return file
}

export const formatAmount = (amount) => {
  if (amount == null) {
    return ''
  }
  return (amount / 100).toFixed(2).replace('.', ',') + ' €'
}

export const extractFromCheckboxes = (element) => {
  const obj = {}
  if (element.length) {
    for (let i = 0; i < element.length; i++) {
      if (element[i].checked) {
        obj[element[i].value] = true
      }
    }
  } else {
    if (element.checked) {
      obj[element.value] = true
    }
  }
  return obj
}

export const DAYS = {
  1: 'lundi',
  2: 'mardi',
  3: 'mercredi',
  4: 'jeudi',
  5: 'vendredi',
  6: 'samedi',
  7: 'dimanche',
}

export const ICONS = {
  new: '➕',
  // Product:
  beer: '🍺',
  bread: '🍞',
  pack: '📦',
  other: '🎁',
  temporary: '⏱',
  permanent: '🏠',
  disabled: '🚫',
  // Stripe order:
  deposit: '📦',
  home: '🏠',
  subscription: '🗓',
  card: '💳',
  cash: '💶',
  // Event:
  event: '📅',
  // Place:
  sale: '🛍',
  restaurant: '🍽',
}

export const PARTNER_CHOICES = process.env.REACT_APP_PARTNERS.split(',').map((id) => ({
  id,
  title: id.toUpperCase(),
}))
PARTNER_CHOICES.unshift({
  id: '_public',
  title: 'Grand public (site principal)',
})
