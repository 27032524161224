import React, {Component} from 'react'
import {connect} from 'react-redux'
import CloseIcon from 'react-icons/lib/md/close'

import {db} from './firebase'
import {Choices, Input, Textarea} from './fields'
import {extractFromCheckboxes, DAYS, ICONS} from './utils'

const collection = db.collection('places')

const TYPE_CHOICES = [
  {id: 'deposit', title: 'Point de retrait'},
  {id: 'sale', title: 'Point de vente'},
  {id: 'restaurant', title: 'Restaurant'},
]

const PARTNER_CHOICES = process.env.REACT_APP_PARTNERS.split(',').map((id) => ({
  id,
  title: id.toUpperCase(),
}))
PARTNER_CHOICES.unshift({
  id: '_public',
  title: 'Grand public (site principal)',
})
const PARTNER_DEFAULT = {_public: true}

class Place extends Component {
  state = {
    edit: false,
    submitting: false,
    error: null,
  }

  handleToggle = () => {
    this.setState({
      edit: !this.state.edit,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const {elements} = event.target

    this.setState({
      submitting: true,
      error: null,
    })

    const title = elements.title.value.trim()

    const types = extractFromCheckboxes(elements.types)
    const breadDays = extractFromCheckboxes(elements.breadDays)
    const beerDays = extractFromCheckboxes(elements.beerDays)
    const partners = extractFromCheckboxes(elements.partners)

    const {place} = this.props

    const doc = place.id ? collection.doc(place.id) : collection.doc()

    doc.set({
      position: Number(elements.position.value) || null,
      title,
      types,
      partners,
      description: elements.description.value.trim(),
      lat: Number(elements.lat.value.trim()) || null,
      lon: Number(elements.lon.value.trim()) || null,
      address: elements.address.value.trim() || null,
      breadDays,
      beerDays,
    })
    .then(() => {
      this.setState({
        submitting: false,
        edit: false,
      })
    })
    .catch((error) => {
      this.setState({
        submitting: false,
        error,
      })
    })
  }

  handleDelete = () => {
    if (confirm('Supprimer ce lieu ?!')) { // eslint-disable-line no-restricted-globals
      collection.doc(this.props.place.id).delete()
    }
  }

  render() {
    const {edit, submitting, error} = this.state
    const {place, dayChoices} = this.props

    if (!edit) {
      let icon = ICONS.new
      if (place.types) {
        if (place.types.sale) {
          icon = ICONS.sale
        } else if (place.types.deposit) {
          icon = ICONS.deposit
        } else if (place.types.restaurant) {
          icon = ICONS.restaurant
        } else {
          icon = ICONS.disabled
        }
      }
      return <div className="item"><a onClick={this.handleToggle}>{icon} {place.title || 'Nouveau lieu'}</a></div>
    }

    return (
      <form className="inline" onSubmit={this.handleSubmit}>
        <a className="close" onClick={this.handleToggle}><CloseIcon /></a>
        <Input name="position" label="Position" defaultValue={place.position} placeholder="Plus grande = plus bas" type="number" step="1" />
        <Choices name="types" label="Type" choices={TYPE_CHOICES} defaultValue={place.types} />
        <Choices name="partners" label="Disponible pour" choices={PARTNER_CHOICES} defaultValue={place.partners || PARTNER_DEFAULT} />
        <Input name="title" label="Nom" defaultValue={place.title} required={true} />
        <Input name="lat" label="Latitude" defaultValue={place.lat} />
        <Input name="lon" label="Longitude" defaultValue={place.lon} />
        <Textarea name="description" label="Horaires" defaultValue={place.description} />
        <Textarea name="address" label="Adresse" defaultValue={place.address} />
        <Choices name="breadDays" label="Retrait du pain pour les abonnés" choices={dayChoices} defaultValue={place.breadDays} />
        <Choices name="beerDays" label="Retrait de la bière pour les abonnés" choices={dayChoices} defaultValue={place.beerDays} />
        <button type="submit" disabled={submitting}>Enregistrer</button>
        {place.id && <a onClick={this.handleDelete} className="delete">Supprimer</a>}
        {error && <p className="error">{error.message}</p>}
      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  dayChoices: state.settings ? Object.keys(state.settings.subscribe.delivery_days).map((id) => ({id, title: DAYS[id]})) : [],
})

export default connect(mapStateToProps)(Place)
