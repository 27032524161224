import React, {Component} from 'react'
import {DateTime} from 'luxon'
import {db, suffix} from './firebase'
import {formatAmount} from './utils'

const pad = (num) => (num > 9) ? String(num) : ('0' + num)

const STRIPE_TAX_PERCENT = 1.4
const STRIPE_TAX_FIXED = 25 // cents
const STRIPE_SMALL_TAX_PERCENT = 5
const STRIPE_SMALL_TAX_FIXED = 5 // cents
const STRIPE_SMALL_LIMIT = 500 // cents

const getTotal = (orders, tax) => orders.reduce((sum, order) => {
  const add = order.products.reduce((sub, product) => sub + (product.price * product.amount), 0)

  if (tax) {
    if (order.stripe_intent || order.subscription || order.charge) {
      return sum + (add > STRIPE_SMALL_LIMIT ? (STRIPE_TAX_FIXED + (STRIPE_TAX_PERCENT * add / 100)) : (STRIPE_SMALL_TAX_FIXED + (STRIPE_SMALL_TAX_PERCENT * add / 100)))
    }
    return sum // personal order (outside of Stripe)
  }

  return sum + add
}, 0)

const collection = db.collection('orders' + suffix)

const toWeekGroup = (snapshot) => {
  let year
  let week
  const years = []
  snapshot.forEach((doc) => {
    const order = doc.data()
    order.id = doc.id
    const date = DateTime.fromISO(order.event)
    if (date.year !== year) {
      year = date.year
      years.push({
        year,
        weeks: [],
      })
    }
    const currentYear = years[years.length - 1]
    if (date.weekNumber !== week) {
      week = date.weekNumber
      currentYear.weeks.push({
        week,
        orders: [],
      })
    }
    currentYear.weeks[currentYear.weeks.length - 1].orders.push(order)
  })
  return years.map(({year, weeks}) => ({
    year,
    weeks: weeks.map(({week, orders}) => ({
      week,
      total: getTotal(orders, false),
      tax: getTotal(orders, true),
    }))
  }))
}

const PAGING = 10

const now = DateTime.local()

class Accounting extends Component {
  weeks = PAGING
  size = 0

  state = {
    loading: false,
    data: [],
    error: null,
  }

  load = () => {
    this.setState({
      loading: true,
    })
    const fromDate = DateTime.local().minus({weeks: this.weeks}).set({weekday: 1}).toISODate() // start with a full week
    this.unsubscribe = collection.where('event', '>=', fromDate).orderBy('event', 'desc').onSnapshot((snapshot) => {
      if (snapshot.size < this.size) {
        return
      }
      this.setState({
        data: toWeekGroup(snapshot),
        loading: false,
      })
      this.size = snapshot.size
    }, (error) => {
      this.setState({error})
    })
  }

  componentDidMount() {
    this.load()
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  handleMore = () => {
    this.unsubscribe()
    this.weeks += PAGING
    this.load()
  }

  render() {
    const {loading, data, error} = this.state

    if (error) {
      return <div>{error}</div>
    }

    return (
      <>
        {data.map(({year, weeks}) => (
          <div key={year}>
            <h2>{year}</h2>
            <table>
              <thead>
                <tr>
                  <th>Semaine</th>
                  <th>Total</th>
                  <th>Stripe</th>
                  <th>Net</th>
                </tr>
              </thead>
              <tbody>
                {weeks.map(({week, total, tax}) => {
                  const start = DateTime.fromISO(`${year}-W${pad(week)}`)
                  const end = start.plus({days: 6})
                  const tmp = (start.year >= now.year && (start.weekNumber >= now.weekNumber || start.year > now.year))
                  return (
                    <tr key={week} className={tmp ? 'tmp' : null}>
                      <td>{start.toFormat('dd/MM')}–{end.toFormat('dd/MM')}{tmp ? ' *' : ''}</td>
                      <td>{formatAmount(total, true)}</td>
                      <td>{formatAmount(tax, true)}</td>
                      <td>{formatAmount(total - tax, true)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ))}
        <button onClick={this.handleMore} disabled={loading}>{loading ? 'Chargement...' : 'Voir plus'}</button>
        <footer><i>* semaine non passée : montants provisoires</i></footer>
      </>
    )
  }
}

export default Accounting
