import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API,
  authDomain: `${process.env.REACT_APP_FIREBASE_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.REACT_APP_FIREBASE_ID}.firebaseio.com`,
  projectId: process.env.REACT_APP_FIREBASE_ID,
  storageBucket: `${process.env.REACT_APP_FIREBASE_ID}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING,
})

export const auth = app.auth()
export const db = app.firestore()
export const storage = app.storage()
export const documentId = firebase.firestore.FieldPath.documentId
export const timestamp = firebase.firestore.FieldValue.serverTimestamp

export const suffix = /local|test/.test(window.location.hostname) ? '_test' : ''

export const toArray = (snapshot, orderBy, reverse) => {
  const data = []
  snapshot.forEach((doc) => {
    data.push({id: doc.id, ...doc.data()})
  })
  if (orderBy) {
    data.sort((a, b) => (a[orderBy] > b[orderBy] ? 1 : -1) * (reverse ? -1 : 1))
  }
  return data
}

export const toGroups = (snapshot, groupBy, filter) => {
  const tmp = {}
  snapshot.forEach((doc) => {
    const data = doc.data()
    if (filter && !filter(data)) {
      return
    }
    data.id = doc.id
    if (!tmp[data[groupBy]]) {
      tmp[data[groupBy]] = []
    }
    tmp[data[groupBy]].push(data)
  })
  const output = []
  for (const key in tmp) {
    tmp[key].sort((a, b) => {
      if (!a.time || !b.time) {
        return 0 // time not set yet (firebase)
      }
      return a.time.toMillis() > b.time.toMillis() ? 1 : -1
    })
    output.push({
      [groupBy]: key,
      items: tmp[key],
    })
  }
  output.sort((a, b) => {
    if (a[groupBy] > b[groupBy]) {
      return 1
    }
    if (a[groupBy] < b[groupBy]) {
      return -1
    }
    return 0
  })
  return output
}
