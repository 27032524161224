import React, {Component} from 'react'
import {connect} from 'react-redux'
import {DateTime} from 'luxon'
import CloseIcon from 'react-icons/lib/md/close'

import {db, suffix} from './firebase'

import {Input, Choices, Select} from './fields'
import {extractFromCheckboxes, ICONS} from './utils'

const hours = []
for (let h = 0; h < 24; h++) {
  hours.push(h)
}

const collection = db.collection('events')

class Event extends Component {
  state = {
    edit: false,
    submitting: false,
    error: null,
  }

  handleToggle = () => {
    this.setState({
      edit: !this.state.edit,
    })
  }

  handleSubmit = (ev) => {
    ev.preventDefault()

    const {elements} = ev.target

    this.setState({
      submitting: true,
      error: null,
    })

    const id = elements.date.value

    const {event} = this.props

    const products = extractFromCheckboxes(elements.products)
    const bread_places = extractFromCheckboxes(elements.bread_places)
    const beer_places = extractFromCheckboxes(elements.beer_places)
    const other_places = extractFromCheckboxes(elements.other_places)

    let orderedBreads = 0
    let orderedBeers = 0
    let orderedOthers = 0
    db.collection('orders' + suffix).where('event', '==', id).get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        const order = doc.data()
        order.products.forEach((product) => {
          if (product.type === 'bread') {
            orderedBreads += product.amount
          }
          if (product.type === 'beer') {
            orderedBeers += product.amount
          }
          if (product.type === 'other') {
            orderedOthers += product.amount
          }
        })
      })
      return collection.doc(id).get()
    })
    .then((doc) => {
      if (doc.exists && (!event.id || event.id !== id)) {
        throw new Error('Il y a déjà une vente à cette date')
      }
      return collection.doc(id).set({
        products,
        bread_places,
        beer_places,
        other_places,
        deadline: elements.deadline.value,
        deadline_hour: Number(elements.deadline_hour.value),
        ['orderedBreads' + suffix]: orderedBreads,
        ['orderedBeers' + suffix]: orderedBeers,
        ['orderedOthers' + suffix]: orderedOthers,
      })
    })
    .then(() => {
      this.setState({
        submitting: false,
        edit: false,
      }, () => {
        // silently delete previous document (renaming = add then delete)
        if (event.id && event.id !== id) {
          collection.doc(event.id).delete()
        }
      })
    })
    .catch((error) => {
      this.setState({
        submitting: false,
        error,
      })
    })
  }

  handleDelete = () => {
    if (confirm('Supprimer cette vente ?!')) { // eslint-disable-line no-restricted-globals
      collection.doc(this.props.event.id).delete()
    }
  }

  render() {
    const {edit, submitting, error} = this.state
    const {event, productChoices, placeChoices} = this.props

    let title = ICONS.new + ' Nouvelle vente'
    if (event.id) {
      title = ICONS.event + ' ' + DateTime.fromISO(event.id).toFormat('DDDD')
    }

    if (!edit) {
      return <div className="item"><a onClick={this.handleToggle}>{title}</a></div>
    }

    return (
      <form className="inline" onSubmit={this.handleSubmit}>
        <a className="close" onClick={this.handleToggle}><CloseIcon /></a>
        <Input type="date" name="date" label="Date de la fournée et tournée" defaultValue={event.id} required={true} />
        <Input type="date" name="deadline" label="Date limite pour commander" defaultValue={event.deadline} required={true} />
        <Select name="deadline_hour" label="Heure limite pour commander" defaultValue={Number.isInteger(event.deadline_hour) ? event.deadline_hour : 22}>
          {hours.map((hour) => <option key={hour} value={hour}>{hour}h</option>)}
        </Select>
        <Choices name="products" label="Produits éphémères" choices={productChoices} defaultValue={event.products || true} />
        <Choices name="bread_places" label="Points de livraison pain" choices={placeChoices} defaultValue={event.bread_places || event.places || true} />
        <Choices name="beer_places" label="Points de livraison bière" choices={placeChoices} defaultValue={event.beer_places || true} />
        <Choices name="other_places" label="Points de livraison accessoires" choices={placeChoices} defaultValue={event.other_places || true} />
        <button type="submit" disabled={submitting}>Enregistrer</button>
        {event.id && <a onClick={this.handleDelete} className="delete">Supprimer</a>}
        {error && <p className="error">{error.message}</p>}
      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  productChoices: state.products ? state.products.filter(({availability}) => availability === 'temporary') : [],
  placeChoices: state.places ? state.places.filter(({types}) => types.deposit) : [],
})

export default connect(mapStateToProps)(Event)
