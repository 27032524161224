import React, {Component} from 'react'
import {connect} from 'react-redux'
import CloseIcon from 'react-icons/lib/md/close'
import {DateTime} from 'luxon'

import {db, suffix, timestamp} from './firebase'
import {Input, Products, Select, Textarea} from './fields'
import {ICONS} from './utils'

const collection = db.collection('orders' + suffix)

const countReducer = (type) => (prev, curr) => ((curr.type === type) ? (prev + curr.amount) : 0)

class PersonalOrder extends Component {
  state = {
    edit: false,
    submitting: false,
    error: null,
  }

  handleToggle = () => {
    this.setState({
      edit: !this.state.edit,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const {elements} = event.target

    this.setState({
      submitting: true,
      error: null,
    })

    const {order, productChoices, placeChoices} = this.props

    const doc = order.id ? collection.doc(order.id) : collection.doc()

    const date = elements.event.value

    const place = {
      id: elements.place.value,
    }
    if (place.id === 'home') {
      place.name = 'A domicile'
      place.address = elements.address.value.trim()
    } else {
      const placeData = placeChoices.find(({id}) => id === place.id)
      place.name = placeData.title
      place.address = placeData.address
    }

    const products = []
    for (let i = 0; i < elements.length; i++) {
      const name = elements[i].name.split('.')
      if (name[0] === 'products') {
        const productId = name[1]
        const product = productChoices.find(({id}) => (id === productId))
        const amount = Number(elements[i].value)
        if (amount) {
          products.push({
            id: productId,
            amount,
            price: product.price,
            name: product.title,
            weight: product.weight,
            type: product.type,
          })
        }
      }
    }

    const data = {
      event: date,
      name: elements.name.value.trim(),
      email: elements.email.value.trim(),
      phone: elements.phone.value.trim(),
      notes: elements.notes.value.trim(),
      place,
      products,
      time: order.time || timestamp(),
    }

    doc.set(data)
    .then(() => {
      let orderedBreads = products.reduce(countReducer('bread'), 0)
      let orderedBeers = products.reduce(countReducer('beer'), 0)
      let orderedOthers = products.reduce(countReducer('other'), 0)

      if (order.products) {
        orderedBreads -= order.products.reduce(countReducer('bread'), 0)
        orderedBeers -= order.products.reduce(countReducer('beer'), 0)
        orderedOthers -= order.products.reduce(countReducer('other'), 0)
      }

      const eventRef = db.collection('events').doc(date)
      return db.runTransaction((transaction) => transaction.get(eventRef).then((document) => {
        transaction.update(eventRef, {
          ['orderedBreads' + suffix]: (document.data()['orderedBreads' + suffix] || 0) + orderedBreads,
          ['orderedBeers' + suffix]: (document.data()['orderedBeers' + suffix] || 0) + orderedBeers,
          ['orderedOthers' + suffix]: (document.data()['orderedOthers' + suffix] || 0) + orderedOthers,
        })
      }))
    })
    .then(() => {
      this.setState({
        submitting: false,
        edit: false,
      })
    })
    .catch((error) => {
      this.setState({
        submitting: false,
        error,
      })
    })
  }

  handleDelete = () => {
    if (confirm('Supprimer cette commande ?!')) { // eslint-disable-line no-restricted-globals
      collection.doc(this.props.order.id).delete()
    }
  }

  render() {
    const {edit, submitting, error} = this.state
    const {order, eventChoices, productChoices, placeChoices} = this.props
    const {id, event, name, email, phone, products, place, address, notes} = order

    if (!edit) {
      const icon = ICONS[place ? (place.id === 'home' ? 'home' : 'deposit') : 'new']
      return <div className="item"><a onClick={this.handleToggle}>{ICONS.cash} {icon} {name || 'Nouvelle commande'}</a></div>
    }

    return (
      <form className="inline" onSubmit={this.handleSubmit}>
        <a className="close" onClick={this.handleToggle}><CloseIcon /></a>
        <Select name="event" label="Vente" defaultValue={event}>
          {eventChoices.map((item) => <option key={item.id} value={item.id}>{DateTime.fromISO(item.id).toFormat('DDDD')}</option>)}
        </Select>
        <Input name="name" label="Prénom Nom" defaultValue={name} required={true} />
        <Input name="email" label="E-mail" defaultValue={email} />
        <Input name="phone" label="Téléphone" defaultValue={phone} />
        <Products name="products" label="Produits" choices={productChoices} defaultValue={products} />
        <Select name="place" label="Retrait" defaultValue={place && place.id}>
          {placeChoices.map((item) => <option key={item.id} value={item.id}>{item.title}</option>)}
          <option value="home">À domicile</option>
        </Select>
        <Input name="address" label="Adresse si domicile" defaultValue={address} />
        <Textarea name="notes" label="Notes internes" defaultValue={notes} />
        <button type="submit" disabled={submitting}>Enregistrer</button>
        {id && <a onClick={this.handleDelete} className="delete">Supprimer</a>}
        {error && <p className="error">{error.message}</p>}
      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  productChoices: state.products ? state.products.filter(({availability}) => availability !== 'disabled') : [],
  placeChoices: state.places || [],
  eventChoices: state.events || [],
})

export default connect(mapStateToProps)(PersonalOrder)
